.container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #000000;
}
.container1 {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.container2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
}
.FeatureCard {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.image {
  width: 500px;
  object-fit: cover;
}
.Footer {
  right: 20px;
  width: 100%;
  bottom: 20px;
  position: fixed;
  max-width: var(--dl-size-size-maxwidth);
  text-align: right;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.text {
  color: #ffffff;
  font-style: normal;
  text-align: right;
  font-family: Inter;
  font-weight: 200;
}
@media(max-width: 991px) {
  .image {
    width: 300px;
  }
}
@media(max-width: 767px) {
  .image {
    width: 260px;
  }
  .Footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .text {
    text-align: center;
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .image {
    width: 220px;
  }
  .Footer {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .text {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
}
